/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ApiResponse, ApisauceConfig, HEADERS
} from 'apisauce';
import { create } from 'apisauce';
import type { AxiosHeaders } from 'axios';
import type { JwtPayload } from 'jwt-decode';
import decodeJwt from 'jwt-decode';
import { showErrorToUser } from '../../utils/errors';
import SessionService from './SessionService';

function isTokenInNeedsOfRefresh(jwtToken: string): boolean {
  if (localStorage.developmentFlagToForceRefreshAccessTokenForHostApp) {
    delete localStorage.developmentFlagToForceRefreshAccessTokenForHostApp;
    return true;
  }
  const creationTimeInSeconds = decodeJwt<JwtPayload>(jwtToken).iat!;
  const expirationTimeInSeconds = decodeJwt<JwtPayload>(jwtToken).exp!;
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const secondsUntilTokenExpires = expirationTimeInSeconds - currentTimeInSeconds;
  const halfSessionTimeInSeconds = (expirationTimeInSeconds - creationTimeInSeconds) / 2;
  return secondsUntilTokenExpires < halfSessionTimeInSeconds;
}

function isTokenExpired(jwtToken: string): boolean {
  const expirationTimeInSeconds = decodeJwt<JwtPayload>(jwtToken).exp!;
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  return currentTimeInSeconds >= expirationTimeInSeconds;
}

interface IRequestConfig<T> {
  url: string;
  data?: T;
  config?: ApisauceConfig;
  withAuth?: boolean;
  showErrorsToUser?: boolean;
  throwErrors?: boolean;
  refreshAccessToken?: boolean;
}

export interface IHttpServiceParams {
  baseURL: string;
}

const isSpecialUrl = (url: string, method: string): boolean => {
  return url.split('/')[1] === 'projects' && method === 'delete';
};

function getRandomHex(): string {
  return Math.round(Math.random() * 15).toString(16);
}

const generateSpecialErrorMessage = (response: ApiResponse<any>, method: string, url: string): string => {
  const context = url.split('/')[1];

  if (context === 'sessions' && method === 'post') {
    return 'Login error occurred';
  }

  if (context === 'users' && method === 'post') {
    return 'Signup error occurred';
  }

  if (context === 'password-reset-requests' && method === 'post') {
    return 'Password reset error occurred';
  }

  return 'server error';
};

const logErrorToConsole = (response: ApiResponse<any>, method: string, url: string): void => {
  if (method === 'get' && (url.endsWith('/logo') || url.includes('/logo?c='))) { // See DocumentsService#getInstallerLogoUrl
    return;
  }
  // eslint-disable-next-line no-console
  console.error(new Error(`${method} ${url} failed with status ${response.status}, body: ${JSON.stringify(response.data)}`));
};

const handleResponseProblem = (response: ApiResponse<any>, method: string, url: string, showErrorsToUser: boolean, throwErrors: boolean): void => {
  logErrorToConsole(response, method, url);

  if (showErrorsToUser) {
    const userErrorMessages: string[] = [];

    if (response.data?.messages) {
      response.data.messages.forEach((messageObject: any): void => {
        userErrorMessages.push(messageObject.message);
      });
    } else if (!response.data || isSpecialUrl(method, url)) {
      userErrorMessages.push(generateSpecialErrorMessage(response, method, url));
    } else {
      userErrorMessages.push(response.data.message === 'No message available'
        ? response.data.error
        : response.data.message);
    }

    userErrorMessages.forEach((userErrorMessage: string): void => {
      showErrorToUser(userErrorMessage);
    });
  }

  if (throwErrors) {
    throw new Error(response.data?.message);
  }
};

class HttpService {
  /**
   * `trace-id` part of the request tracing header is a 32 hex digit string.
   * First 14 digits - session, next 2 digits - instance/tab/window, last 16 digits - random.
   */
  static requestTracingSessionId: string = '';
  /**
   * Idea for a future improvement: check for collisions with old (but recent) sessions.
   */
  static requestTracingInstanceId: string = '';
  private api: any;
  private baseURL: string;

  get: <P, R>({
    url, data, config, withAuth, showErrorsToUser, throwErrors
  }: IRequestConfig<P>) => Promise<R>;
  post: <P, R>({
    url, data, config, withAuth, showErrorsToUser, throwErrors
  }: IRequestConfig<P>) => Promise<R>;
  put: <P, R>({
    url, data, config, withAuth, showErrorsToUser, throwErrors
  }: IRequestConfig<P>) => Promise<R>;
  patch: <P, R>({
    url, data, config, withAuth, showErrorsToUser, throwErrors
  }: IRequestConfig<P>) => Promise<R>;
  delete: <P, R>({
    url, data, config, withAuth, showErrorsToUser, throwErrors
  }: IRequestConfig<P>) => Promise<R>;

  constructor({ baseURL }: IHttpServiceParams) {
    this.baseURL = baseURL;
    this.api = create({ baseURL: this.baseURL });
    this.get = this.handleRequest('get');
    this.post = this.handleRequest('post');
    this.put = this.handleRequest('put');
    this.patch = this.handleRequest('patch');
    this.delete = this.handleRequest('delete');

    SessionService.addOnAccessTokenUpdatedEventListener((jwtToken: string): void => {
      let storedTracingSessionJwtToken = '';
      let storedTracingSessionId = '';
      let storedTracingInstanceId: string | undefined;

      // Wrap in try in case of localStorage.requestTracingData absence
      try {
        if (localStorage.requestTracingData) {
          const requestTracingData = JSON.parse(localStorage.requestTracingData);
          storedTracingSessionJwtToken = requestTracingData.jwtToken;
          storedTracingSessionId = requestTracingData.requestTracingSessionId;
          storedTracingInstanceId = requestTracingData.requestTracingInstanceId;
        }
      } catch (error) {}

      HttpService.requestTracingInstanceId = storedTracingInstanceId ?? new Array(2).fill(0)
        .map(getRandomHex)
        .join('');

      if (storedTracingSessionJwtToken === jwtToken) {
        HttpService.requestTracingSessionId = storedTracingSessionId;
      } else {
        HttpService.requestTracingSessionId = new Array(14).fill(0)
          .map(getRandomHex)
          .join('');
        localStorage.requestTracingData = JSON.stringify({
          jwtToken,
          requestTracingSessionId: HttpService.requestTracingSessionId,
          requestTracingInstanceId: HttpService.requestTracingInstanceId
        });
      }
    });
  }

  getHeaders = async (config: ApisauceConfig, withAuth?: boolean): Promise<HEADERS> => {
    const headers = { ...config.headers } as AxiosHeaders;
    if (withAuth) {
      const { accessToken } = await SessionService.getSession();
      headers.Authorization = `Bearer ${accessToken.value}`;
    }

    // This is going to be a random 16 hex characters string
    const requestTracingSpanId = new Array(16).fill(0)
      .map(getRandomHex)
      .join('');
    const requestTracingRequestId = new Array(16).fill(0)
      .map(getRandomHex)
      .join('');
    // `trace-id` part of the request tracing header is a 32 hex digit string.
    // For convenience, we'll use part of the `trace-id` to track user sessions
    // We'll use part of the `trace-id` header value to help debugging by tracking
    // user sessions and tabs/instances. The convention we'll use:
    // First 14 digits - session, next 2 digits - instance/tab/window, last 16 digits - random.
    const requestTracingTraceId =
      `${HttpService.requestTracingSessionId}${HttpService.requestTracingInstanceId}${requestTracingRequestId}`;

    if (requestTracingTraceId.length === 32 && requestTracingSpanId.length === 16) {
      // `traceparent` header value has to comply with these rules -
      // https://www.w3.org/TR/trace-context/#traceparent-header
      headers.traceparent = `00-${requestTracingTraceId}-${requestTracingSpanId}-01`;
    }

    return headers;
  };

  getConfig = async (config?: ApisauceConfig | {}, withAuth?: boolean): Promise<ApisauceConfig> => {
    const baseConfig = {
      baseURL: this.baseURL,
      headers: {},
      ...config
    };

    const customHeaders = await this.getHeaders(baseConfig, withAuth);

    return {
      ...baseConfig,
      headers: customHeaders
    };
  };

  private handleRequest = (method: string) =>
    async <P, R>(
      {
        url,
        data,
        config,
        withAuth = true,
        showErrorsToUser = true,
        throwErrors = true,
        refreshAccessToken = true
      }: IRequestConfig<P>
    ): Promise<R> => {
      const { accessToken } = await SessionService.getSession();
      if (withAuth && refreshAccessToken && accessToken && isTokenExpired(accessToken.value)) {
        // Reload to navigate to the login page.
        window.location.reload();
      }
      if (withAuth && refreshAccessToken && accessToken && isTokenInNeedsOfRefresh(accessToken.value)) {
        await SessionService.refreshSession();
      }
      const configObject = await this.getConfig(config, withAuth);

      const response = await this.api[method](url, data, configObject);
      if (response.problem) {
        handleResponseProblem(response, method, url, showErrorsToUser, throwErrors);
      }
      return response.data as R;
    };
}

export default HttpService;
